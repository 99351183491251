<template>
  <div class="login">
    <el-card class="login-card">
      <div slot="header" class="clearfix">
        <span>Логин</span>
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="70px" label-position="left">
        <el-form-item label="логин" prop="login">
          <el-input v-model="form.login" />
        </el-form-item>
        <el-form-item label="пароль" prop="password">
          <el-input v-model="form.password" show-password />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">OK</el-button>
        </el-form-item>
      </el-form>
    </el-card> 
  </div>
</template>

<script>
export default {
  store: ['user'],

  data () {
    return {
      form: {
        login: '',
        password: '',
      },

      rules: {
        login: [{ required: true, message: 'Пожалуйста введите логин', trigger: 'blur' }],
        password: [{ required: true, message: 'Пожалуйста введите пароль', trigger: 'blur' }],
      }
    }
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post('login', { login: this.form.login, password: this.form.password })
            .then(response => {
              this.user.token = response.data
              this.user.init()
              this.$router.replace(this.$route.query.redirect || '/')
            })
            .catch(err => {
              console.log(err)
            })
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.login {
  background-color: @gray2l-color;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-card {
    width: 400px;
  }
}
</style>
